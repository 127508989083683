import { Box, Button, makeStyles } from "@material-ui/core";
import { ArrowDropDown as ArrowDropDownIcon } from "@material-ui/icons";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { DialogWithClose } from "../dialogs";
import { Heading } from "../typography";

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(7),
    },
  },
}));

export const ModalConfirm = ({
  body,
  confirmLabel,
  label,
  onConfirm,
  options,
  title,
  value,
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return (
    <>
      <Button color="primary" variant="contained" onClick={handleOpen}>
        {label}
      </Button>
      <DialogWithClose fullScreen={false} open={isOpen} onClose={handleClose}>
        {title && (
          <Heading variant="h2" underlined={true} className={classes.heading}>
            {title}
          </Heading>
        )}
        {body && <p style={{ textAlign: "center" }}>{body}</p>}
        <Box
          pt={2}
          display="flex"
          flexDirection="row"
          justifyContent="space-around"
        >
          <Button
            onClick={() => {
              handleClose();
            }}
            variant="contained"
          >
            Go Back
          </Button>
          <Button
            color="primary"
            onClick={() => {
              handleClose();
              onConfirm();
            }}
            variant="contained"
          >
            {confirmLabel || "Confirm"}
          </Button>
        </Box>
      </DialogWithClose>
    </>
  );
};

ModalConfirm.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string.isRequired,
};

export default ModalConfirm;
