import {
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import Button from "../form/Button";
import TextFieldFormik from "./TextFieldFormik";

const SignupSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Required"),
});

const useStyles = makeStyles((theme) => ({
  submit: {},
}));

export const UserDetailsForm = ({
  firstName = "",
  lastName = "",
  email = "",
  loading = false,
  error,
  onSubmit,
}) => {
  const classes = useStyles();
  const [formError, setFormError] = useState(error);
  const initialValues = { email, lastName, firstName };

  const handleSubmit = async (values, { setErrors, setSubmitting }) => {
    try {
      setFormError(error);
      await onSubmit(values);
    } catch (err) {
      if (err.form) setFormError(err.form);
      if (err.fields) setErrors(err.fields);
      if (!err.form && !err.fields) {
        setFormError("Error processing");
      }
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => setFormError(error), [error]);

  return (
    <Card>
      <CardContent>
        {formError && (
          <Typography color="error" gutterBottom>
            {formError}
          </Typography>
        )}
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={SignupSchema}
        >
          {({ handleSubmit, isSubmitting, isValid }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    component={TextFieldFormik}
                    fullWidth={true}
                    label="First Name"
                    name="firstName"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextFieldFormik}
                    fullWidth={true}
                    label="Last Name"
                    name="lastName"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextFieldFormik}
                    fullWidth={true}
                    label="Email"
                    name="email"
                    type="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    className={classes.submit}
                    color="primary"
                    disabled={!isValid || isSubmitting}
                    fullWidth
                    loading={loading}
                    onClick={handleSubmit}
                    type="submit"
                    variant="contained"
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
              <div />
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};

export default UserDetailsForm;
