const env = process.env;
const {
  REACT_APP_OJ_USER_POOL_ID,
  REACT_APP_OJ_APP_CLIENT_ID,
  REACT_APP_OJ_API_URL,
} = env;
const region = "eu-west-2";

export default {
  cognito: {
    region,
    userPoolId: REACT_APP_OJ_USER_POOL_ID,
    userPoolWebClientId: REACT_APP_OJ_APP_CLIENT_ID,
  },
  api: {
    url: REACT_APP_OJ_API_URL,
  },
  env,
};
