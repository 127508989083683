import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  makeStyles,
} from "@material-ui/core";
import EllipsisText from "react-ellipsis-text";

import Button from "../form/Button";
import Title from "../layout/Title";
import { useApp, useBenefits, useBenefitAddRequests } from "../../common/hooks";
import BenefitDetails from "../sections/BenefitDetails";
import VoucherList from "../sections/VoucherList";

const useStyles = makeStyles((theme) => ({
  button: {
    marginBottom: 10,
  },
}));

const Benefit = ({ id, type }) => {
  const classes = useStyles();
  const { loading } = useApp();
  const [benefits] = useBenefits();
  const [benefitAddRequests] = useBenefitAddRequests();
  const [tab, setTab] = useState("details");

  let benefit;
  if (type === "request") {
    benefit = benefitAddRequests.find((benefit) => benefit.id == id);
  } else {
    benefit = (benefits || []).find((benefit) => benefit.id == id);
  }

  if (!benefit) return null;

  return (
    <Container maxWidth="lg">
      <Title title={<EllipsisText text={benefit.title} length={"70"} />} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={3}>
          <Button
            color={tab === "details" ? "primary" : ""}
            onClick={() => setTab("details")}
            fullWidth
            variant="contained"
            className={classes.button}
          >
            Details
          </Button>
          {type !== "request" && (
            <>
              {" "}
              <Button
                color={tab === "redemptions" ? "primary" : ""}
                onClick={() => setTab("redemptions")}
                fullWidth
                variant="contained"
                className={classes.button}
              >
                Redeemed Vouchers
              </Button>
              <Button
                color={tab === "assigned" ? "primary" : ""}
                onClick={() => setTab("assigned")}
                fullWidth
                variant="contained"
                className={classes.button}
              >
                Assigned Vouchers
              </Button>
              <Button
                color={tab === "expired" ? "primary" : ""}
                onClick={() => setTab("expired")}
                fullWidth
                variant="contained"
                className={classes.button}
              >
                Expired Vouchers
              </Button>
              <Button
                color={tab === "valid" ? "primary" : ""}
                onClick={() => setTab("valid")}
                fullWidth
                variant="contained"
                className={classes.button}
              >
                Valid Vouchers
              </Button>
            </>
          )}
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <Card>
            <CardContent>
              <Box p={2}>
                {tab === "details" && <BenefitDetails benefit={benefit} />}
                {tab === "redemptions" && (
                  <VoucherList
                    id={benefit.id}
                    status="redeemed"
                    fields={[
                      { key: "membershipNumber", label: "Membership No" },
                      { key: "code", label: "Code" },
                      { key: "redeemedAt", label: "Redeemed At", type: "date" },
                    ]}
                    description="These are vouchers that have been successfully redeemed against this offer."
                  />
                )}
                {tab === "expired" && (
                  <VoucherList
                    id={benefit.id}
                    status="expired"
                    fields={[
                      { key: "membershipNumber", label: "Membership No" },
                      { key: "code", label: "Code" },
                      { key: "expiresAt", label: "Expired At", type: "date" },
                    ]}
                    description="These are vouchers that were assigned to a member, but not marked as redeemed within the 24 hour cooldown."
                  />
                )}
                {tab === "valid" && (
                  <VoucherList
                    id={benefit.id}
                    status="valid"
                    fields={[
                      { key: "code", label: "Code" },
                      { key: "createdAt", label: "Created At", type: "date" },
                    ]}
                    description="When using the pre-generated codes ecommerce integration, your unclaimed vouchers appear here."
                  />
                )}
                {tab === "assigned" && (
                  <VoucherList
                    id={benefit.id}
                    status="assigned"
                    fields={[
                      { key: "membershipNumber", label: "Membership No" },
                      { key: "code", label: "Code" },
                      {
                        key: "requestedAt",
                        label: "Assigned At",
                        type: "date",
                      },
                    ]}
                    description="These are vouchers that have been assigned to a member, but have not yet been marked as redeemed."
                  />
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Benefit;
