import React, { useEffect, useState } from "react";
import { useApiGet } from "../../hooks";
import { parsers } from "../api";
import ProfileContext from "./ProfileContext";

export default ({ children }) => {
  const [disableAppLoader, setDisableAppLoader] = useState(false);
  const [intervalObj, setIntervalObj] = useState();
  const [profile, setProfile] = useState();
  const [data, setPath] = useApiGet({
    disableCache: true,
    path: "/profile/",
    parse: parsers.parseProfile,
    disableAppLoader
  });

  useEffect(() => {
    setDisableAppLoader(true)
    setIntervalObj(setInterval(() => {
      setPath('/profile/')
    }, 5000))
    return () => {
      clearInterval(intervalObj)
    }
  }, [])

  useEffect(() => {
    data && setProfile(data)
  }, [data]);

  return (
    <ProfileContext.Provider
      value={{
        profile,
        setProfile,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
