import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import ImageUploader from "react-images-upload";
import { navigate } from "@reach/router";
import * as Yup from "yup";

import Title from "../layout/Title";
import {
  useApp,
  useApi,
  useProfile,
  usePartnerChangeRequests,
} from "../../common/hooks";
import Button from "../form/Button";
import DateFieldFormik from "../form/DateFieldFormik";
import PhoneInputFormik from "../form/PhoneInputFormik";
import TextFieldFormik from "../form/TextFieldFormik";

const rePhoneNumber = /^\+\d{0,4}\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const useStyles = makeStyles(() => ({
  title: {
    textTransform: "uppercase",
    color: "#989898",
    fontWeight: 400,
    fontSize: 13,
  },
  formContainer: {
    maxWidth: 500,
    margin: "0 auto",
  },
  imageContainer: {
    background: "rgb(255, 255, 255, 0.15)",
    "& img.uploadPicture": {
      objectFit: "cover !important",
      objectPosition: "center !important",
    },
  },
}));

const AccountDetailsSchema = Yup.object().shape({
  description: Yup.string().required("Required"),
  name: Yup.string().required("Required"),
  phoneNumber: Yup.string()
    .transform((value, originalvalue) => {
      return value === "+971" ? "+97111111" : value;
    })
    .matches(
      rePhoneNumber,
      "Please enter valid phone number with country code (e.g. +44)"
    ),
  website: Yup.string()
    .transform((value, originalvalue) => {
      return value === "https://" ? "" : value;
    })
    .url("please enter a valid URL"),
});

const AccountDetails = () => {
  const classes = useStyles();
  const [formError, setFormError] = useState();
  const [image, setImage] = useState(null);
  const [imageError, setImageError] = useState();
  const [profile] = useProfile();
  const [
    partnerChangeRequests,
    setPartnerChangeRequestsPath,
  ] = usePartnerChangeRequests();
  const { loading } = useApp();
  const { displaySnackMessage } = useApp();
  const [_, setRequest, submitting, error] = useApi({
    disableAppLoader: true,
    onSuccess: (response) => {
      displaySnackMessage("Offer submitted for review!", "success");
    },
  });

  useEffect(() => {
    error && setFormError(error.form);
  }, [error]);

  useEffect(() => {
    image && setImageError();
  }, [image]);

  const onSubmit = ({ name, description, website, phoneNumber }) => {
    const body = {
      name,
      description,
      website: website === "https://" ? "" : website,
      phone_number: phoneNumber === "+971" ? "" : phoneNumber,
      image_raw: image,
    };

    setRequest({
      method: "post",
      path: "/partner-change-requests/",
      init: { body },
    });
  };

  const onDrop = (pictureFiles, pictureDataURLs) => {
    setImage(pictureDataURLs[0]);
  };

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setSubmitting }
  ) => {
    if (!image && !partner.image) {
      setImageError("Partner image is required");
      return;
    }
    try {
      setFormError();
      setImageError();
      await onSubmit(values);
      setTimeout(() => {
        navigate(`/`);
      }, 1000);
    } catch (err) {
      if (err.form) setFormError(err.form);
      if (err.fields) setErrors(err.fields);
      if (!err.form && !err.fields) {
        setFormError("Error processing");
      }
    } finally {
      setSubmitting(false);
    }
  };

  if (loading || !profile || partnerChangeRequests === null) return null;

  if (partnerChangeRequests.length) {
    navigate(`/`);
  }

  const { partner } = profile;

  const initialValues = {
    description: partner.description,
    name: partner.name,
    phoneNumber: partner.phoneNumber || "+971",
    website: partner.website || "https://",
  };

  return (
    <Container maxWidth="lg">
      <Title title={`Submit account details for review`} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Box p={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={8} lg={9}>
                    <Box className={classes.formContainer} pt={4}>
                      {formError && (
                        <Typography color="error" gutterBottom>
                          {formError}
                        </Typography>
                      )}
                      <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={AccountDetailsSchema}
                        enableReinitialize
                      >
                        {({ handleSubmit, isSubmitting, isValid }) => (
                          <Form>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Field
                                  component={TextFieldFormik}
                                  fullWidth={true}
                                  label="Partner Name"
                                  name="name"
                                  type="text"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Field
                                  component={TextFieldFormik}
                                  fullWidth={true}
                                  label="Partner Description"
                                  name="description"
                                  type="text"
                                  multiline
                                  rows={4}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <PhoneInputFormik
                                  fullWidth
                                  label="Phone"
                                  name="phoneNumber"
                                  placeholder="+971"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Field
                                  component={TextFieldFormik}
                                  fullWidth={true}
                                  label="Website"
                                  name="website"
                                  type="text"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Box pt={4}>
                                  <Button
                                    className={classes.submit}
                                    color="primary"
                                    disabled={!isValid || isSubmitting}
                                    fullWidth
                                    loading={isSubmitting}
                                    onClick={handleSubmit}
                                    type="submit"
                                    variant="contained"
                                  >
                                    Submit Account Details for Review
                                  </Button>
                                </Box>
                              </Grid>
                            </Grid>
                          </Form>
                        )}
                      </Formik>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4} lg={3}>
                    <Typography
                      display="block"
                      gutterBottom
                      className={classes.title}
                      align="center"
                    >
                      Partner Logo
                    </Typography>

                    <Box className={image ? null : classes.imageContainer}>
                      <ImageUploader
                        withIcon={false}
                        withPreview={true}
                        label=""
                        buttonText={
                          image || partner.image ? "" : "Upload Image"
                        }
                        onChange={onDrop}
                        imgExtension={[".jpg", ".bmp", ".png", ".jpeg"]}
                        maxFileSize={10048576}
                        fileSizeError=" file size is too big"
                        singleImage={true}
                        defaultImages={!image ? (partner.image ? [partner.image] : []) : [image]}
                      />
                    </Box>
                    {imageError && (
                      <Typography color="error" gutterBottom>
                        {imageError}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AccountDetails;
