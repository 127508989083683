import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Link,
  makeStyles,
} from "@material-ui/core";
import { Link as RouterLink } from "@reach/router";

const useStyles = makeStyles(() => ({
  text: {
    fontWeight: "bold",
    color: "#989898",
    marginTop: 10,
    fontSize: 12,
  },
}));

const EmptyCard = ({ text }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Card style={{ height: "100%" }}>
        <CardContent>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              display="block"
              align="center"
              variant="body2"
              className={classes.text}
            >
              {text}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default EmptyCard;
