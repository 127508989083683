import { useEffect, useState } from "react";
import { useApiGet, parsers } from "../modules/api";

export default () => {
	const path = `/benefit-add-requests/`;
	const [data, setPath] = useApiGet({
		disableCache: true,
		path,
		parse: parsers.parseBenefits,
	});
	const [benefitAddRequests, setBenefitAddRequests] = useState([]);

	useEffect(() => data && setBenefitAddRequests(data.items), [data]);

	return [benefitAddRequests, setPath];
};
