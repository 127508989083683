import DateFnsUtils from "@date-io/moment";
import { makeStyles } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import { Location, Router } from "@reach/router";
import { withAuthenticator } from "aws-amplify-react";
import { SnackbarProvider, useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useApp } from "../common/hooks";
import { createApi } from "../common/modules/api";
import { AppProvider } from "../common/modules/app";
import { ProfileProvider } from "../common/modules/profile";
import "./App.css";
import { ForgotPassword, RequireNewPassword, SignIn } from "./auth";
import theme from "./css/theme";
import Header from "./layout/Header";
import {
  AccountDetails,
  Benefit,
  Benefits,
  BenefitSubmit,
  ChangePassword,
  Home,
  Logout,
  MyProfile,
} from "./pages";

const useStyles = makeStyles(() => ({
  main: {
    flex: "1 1 100%",
    margin: "0 auto",
    minHeight: "100%",
    position: "relative",
    paddingTop: theme.spacing(10),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(12),
    },
  },
  notificationError: {
    color: "#fff",
  },
  notificationSuccess: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const AppMessages = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { error, snackMessage, closeSnackbar } = useApp();

  useEffect(() => {
    if (!snackMessage) {
      closeSnackbar();
      return;
    }

    const { message, type } = snackMessage;

    enqueueSnackbar(message, { variant: type });
  }, [snackMessage]);

  useEffect(() => {
    error
      ? enqueueSnackbar(error.message, { variant: "error" })
      : closeSnackbar();
  }, [error]);

  return null;
};

const Route = ({ children }) => children;

const Layout = () => {
  const classes = useStyles();

  return (
    <ProfileProvider>
      <Location>
        {({ location }) => (
          <>
            <Header path={location.pathname} />
            <main className={classes.main}>
              <Router>
                <Home path="/" />
                <Route path="offers">
                  <BenefitSubmit path="/submit/:type/*" />
                  <Benefit path=":id/*" />
                  <Benefit path="/request/:id/*" type="request" />
                  <Benefits path="/" />
                </Route>
                <Route path="account" onChange={() => console.log("hello")}>
                  <AccountDetails path="details" />
                  <ChangePassword path="password" />
                  <Logout path="logout" />
                  <MyProfile path="profile" />
                </Route>
              </Router>
            </main>
          </>
        )}
      </Location>
    </ProfileProvider>
  );
};

const LayoutWithAuthenticator = withAuthenticator(
  Layout,
  false,
  [<SignIn />, <ForgotPassword />, <RequireNewPassword />],
  null,
  { toast: { display: "none" } }
);

const api = createApi();

function App() {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <SnackbarProvider
          maxSnack={3}
          classes={{
            variantError: classes.notifictionError,
            variantSuccess: classes.notificationSuccess,
          }}
        >
          <AppProvider api={api}>
            <CssBaseline />
            <Router className="router">
              <LayoutWithAuthenticator path="/*" />
            </Router>
            <AppMessages />
          </AppProvider>
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

export default App;
