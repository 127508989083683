import {
  Card,
  CardContent,
  Grid,
  Typography,
  Link,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";

import Button from "../form/Button";
import Heading from "../typography/Heading";
import PasswordFieldFormik from "./PasswordFieldFormik";
import TextFieldFormik from "./TextFieldFormik";

const SendSchema = Yup.object().shape({
  username: Yup.string()
    .email("Invalid email")
    .required("Required"),
});

const SubmitSchema = Yup.object().shape({
  code: Yup.number().required("Required"),
  password: Yup.string()
    .min(8)
    .required("Required"), // TODO check password length
});

const useStyles = makeStyles((theme) => ({
  link: {
    cursor: "pointer",
  },
  linkArea: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(1.5),
  },
  title: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1),
  },
}));

const SendView = ({ disabled, isSubmitting, onSubmit }) => (
  <>
    <Grid item xs={12}>
      <Field
        component={TextFieldFormik}
        fullWidth={true}
        label="Email"
        name="username"
        type="email"
      />
    </Grid>
    <Grid item xs={12}>
      <Button
        color="primary"
        disabled={disabled}
        fullWidth
        loading={isSubmitting}
        onClick={onSubmit}
        type="submit"
        variant="contained"
      >
        Send Code
      </Button>
    </Grid>
  </>
);

const SubmitView = ({ disabled, isSubmitting, onSubmit }) => (
  <>
    <Grid item xs={12}>
      <Field
        component={TextFieldFormik}
        fullWidth={true}
        label="Code"
        name="code"
      />
    </Grid>
    <Grid item xs={12}>
      <Field
        component={PasswordFieldFormik}
        fullWidth={true}
        label="Password"
        name="password"
      />
    </Grid>
    <Grid item xs={12}>
      <Button
        color="primary"
        disabled={disabled}
        fullWidth
        loading={isSubmitting}
        onClick={onSubmit}
        type="submit"
        variant="contained"
      >
        Submit
      </Button>
    </Grid>
  </>
);

export const ForgotPasswordForm = ({
  username = "",
  code = "",
  password = "",
  codeSent = false,
  error,
  onSendClick,
  onSubmitClick,
  onBackClick,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [formError, setFormError] = useState();

  const initialValuesSend = { username };
  const initialValuesSubmit = { code, password };

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setSubmitting }
  ) => {
    try {
      setFormError();
      await (codeSent ? onSubmitClick : onSendClick)(values);
      resetForm(values);
    } catch (err) {
      if (err.form) setFormError(err.form);
      if (err.fields) setErrors(err.fields);
      if (!err.form && !err.fields) {
        setFormError("Error processing");
      }
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    error && setFormError(error.form);
  }, [error]);

  return (
    <Card>
      <CardContent>
        <Heading variant="h3" className={classes.title}>
          Forgot Password
        </Heading>

        {formError && (
          <Typography color="error" gutterBottom>
            {formError}
          </Typography>
        )}

        <Formik
          enableReinitialize
          initialValues={codeSent ? initialValuesSubmit : initialValuesSend}
          onSubmit={handleSubmit}
          validationSchema={codeSent ? SubmitSchema : SendSchema}
        >
          {({ handleSubmit, isSubmitting, isValid }) => (
            <Form>
              <Grid container spacing={2}>
                {codeSent ? (
                  <SubmitView
                    onSubmit={handleSubmit}
                    disabled={!isValid || isSubmitting}
                    isSubmitting={isSubmitting}
                  />
                ) : (
                  <SendView
                    onSubmit={handleSubmit}
                    disabled={!isValid || isSubmitting}
                    isSubmitting={isSubmitting}
                  />
                )}
              </Grid>
            </Form>
          )}
        </Formik>

        {codeSent && (<div className={classes.linkArea}>
          <Link className={classes.link} onClick={onSendClick}>
            Resend code
          </Link>
        </div>)}
        <div className={classes.linkArea}>
          <Link className={classes.link} onClick={onBackClick}>
            Back to sign in
          </Link>
        </div>
      </CardContent>
    </Card>
  );
};

export default ForgotPasswordForm;
