import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'formik'
import PhoneInput from 'material-ui-phone-number'

export const PhoneInputFormik = ({ ...props }) => {
  const [field, meta, helpers] = useField(props)
  const { touched, error } = meta

  const handleChange = value => {
    helpers.setValue(value.replace(/[\s\(\)-]/g, ''))
  }

  return (
    <PhoneInput
      {...props}
      {...field}
      onChange={handleChange}
      error={Boolean(touched && error)}
      helperText={touched && error}
      variant="outlined"
    />
  )
}

PhoneInputFormik.propTypes = {
  // `field` and `form` passed in from Formik
  // - https://github.com/jaredpalmer/formik/blob/master/src/Field.tsx
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
}

export default PhoneInputFormik
