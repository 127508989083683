import React, { useState } from "react";
import { ApiContext } from "../api";
import AppContext from "./AppContext";

export default ({ children, api }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [snackMessage, setSnackMessage] = useState();

  const displaySnackMessage = (message, type, duration) =>
    setSnackMessage({ type, message, duration });

  const closeSnackbar = () => setSnackMessage();

  return (
    <ApiContext.Provider value={api}>
      <AppContext.Provider
        value={{
          closeSnackbar,
          displaySnackMessage,
          error,
          loading,
          setError,
          setLoading,
          setSnackMessage,
          snackMessage,
        }}
      >
        {children}
      </AppContext.Provider>
    </ApiContext.Provider>
  );
};
