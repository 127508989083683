import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Link,
  makeStyles,
} from "@material-ui/core";
import { Link as RouterLink } from "@reach/router";
import { useProfile } from "../../common/hooks";
import Button from "../form/Button";

const useStyles = makeStyles(() => ({
  text: {
    fontWeight: "bold",
    color: "#989898",
    marginTop: 10,
    fontSize: 12,
  },
  link: {
    textDecoration: "none !important",
  },
}));

const ReplaceCard = ({ isAdd, type }) => {
  const classes = useStyles();
  const [profile] = useProfile();

  if (!profile) return null;

  let updateAllowed = true;
  if (type === "long_term") {
    if (
      profile.partner.updateAllowedDate &&
      moment(profile.partner.updateAllowedDate) > moment()
    ) {
      updateAllowed = false;
    }
  }

  const typeText = type === "long_term" ? "long term" : "short term";
  const actionText = isAdd ? "Add a" : "Replace your";

  return (
    <Grid item xs={12} md={6}>
      <Card style={{ height: "100%" }}>
        <CardContent style={{ height: "100%" }}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            style={{ height: "100%" }}
          >
            {!updateAllowed && (
              <>
                <Typography
                  display="block"
                  align="center"
                  variant="h5"
                  gutterBottom
                >
                  {actionText} {typeText} offer
                </Typography>
                <Typography display="block" align="center" variant="body2">
                  You will be able to submit a new {typeText} offer from{" "}
                  <strong>
                    {moment(profile.partner.updateAllowedDate).format(
                      "YYYY-MM-DD HH:MM"
                    )}
                  </strong>
                  .
                </Typography>
              </>
            )}
            {updateAllowed && (
              <>
                <Typography
                  display="block"
                  align="center"
                  variant="h5"
                  gutterBottom
                >
                  {actionText} {typeText} offer
                </Typography>
                <Typography display="block" align="center" variant="body2">
                  Click below to submit a new {typeText} offer for review.
                </Typography>
                <Box pt={4}>
                  <Link
                    component={RouterLink}
                    to={`/offers/submit/${type}`}
                    className={classes.link}
                  >
                    <Button color="primary" variant="contained">
                      Submit new {typeText} offer
                    </Button>
                  </Link>
                </Box>
              </>
            )}
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ReplaceCard;
