import { Container } from "@material-ui/core";
import { navigate } from "@reach/router";
import { Hub } from "aws-amplify";
import { SignIn as BaseSignIn } from "aws-amplify-react";
import React from "react";
import SignInForm from "../form/SignInForm";
import logo from "../layout/logo-x.png";
import createInputChangeEvent from "./createInputChangeEvent";

const initialState = {
  error: null,
};

class SignIn extends BaseSignIn {
  constructor(props) {
    super(props);

    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
    this.state = {
      ...this.state,
      ...initialState,
    };
  }

  componentDidMount = () => {
    Hub.listen("auth", this.handleAuthEvent);
  };

  componentWillUnmount = () => {
    Hub.remove("auth", this.handleAuthEvent);
  };

  handleAuthEvent = ({ payload }) => {
    this.setState({
      error: payload.event === "signIn_failure" ? payload.data : null,
    });
  };

  handleSubmit = async (data) => {
    Object.entries(data)
      .map(createInputChangeEvent)
      .forEach(this.handleInputChange);

    const result = await this.signIn();
    console.log(result);
    return result;
  };

  handleForgotPasswordClick = () => {
    this.changeState("forgotPassword");
    this.setState(initialState);
  };

  handleSignUpClick = () => {
    this.setState(initialState);
    navigate("/sign-up");
  };

  showComponent = () => (
    <Container maxWidth="xs">
      <img
        src={logo}
        alt="logo"
        style={{ width: "100%", padding: "100px 30px 30px" }}
      />
      <SignInForm
        error={this.state.error && { form: this.state.error.message }}
        onSubmit={this.handleSubmit}
        onForgotPasswordClick={this.handleForgotPasswordClick}
        onSignUpClick={this.handleSignUpClick}
      />
    </Container>
  );
}

export default SignIn;
