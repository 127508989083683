import { useEffect, useState } from "react";
import { useApiGet, parsers } from "../modules/api";

export default () => {
  const path = `/benefits/`;
  const [data, setPath] = useApiGet({
    disableCache: true,
    path,
    parse: parsers.parseBenefits,
  });
  const [benefits, setBenefits] = useState(null);

  useEffect(() => data && setBenefits(data.items), [data]);

  return [benefits];
};
