import { useEffect, useState } from "react";
import { useApiGet, parsers } from "../modules/api";

export default () => {
	const path = `/partner-change-requests/`;
	const [disableAppLoader, setDisableAppLoader] = useState(false);
	const [intervalObj, setIntervalObj] = useState();
	const [data, setPath] = useApiGet({
		disableCache: true,
		path,
		parse: parsers.parseBenefits,
		disableAppLoader
	});

	useEffect(() => {
		setDisableAppLoader(true)
	    setIntervalObj(setInterval(() => {
	        setPath('/partner-change-requests/')
	    }, 5000))
	    return () => {
	        clearInterval(intervalObj)
	    }
	}, [])

	const [partnerChangeRequests, setPartnerChangeRequests] = useState(null);

	useEffect(() => data && setPartnerChangeRequests(data.items), [data]);

	return [partnerChangeRequests, setPath];
};
