import React from 'react'

export default (useHook, propName) => Component => props => {
  const hook = useHook()

  return (
    <Component
      {...{
        ...props,
        ...(propName ? { [propName]: hook } : hook),
      }}
    />
  )
}
