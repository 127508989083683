import {
  AppBar,
  Button,
  Container,
  LinearProgress,
  Link,
  Toolbar,
  makeStyles,
} from "@material-ui/core";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Link as RouterLink } from "@reach/router";
import { useApp } from "../../common/hooks";

import logo from "./logo-x.png";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  link: {
    "&:hover": {
      textDecoration: "none",
    },
  },
  linkButtonActive: {
    color: theme.palette.primary.main,
    textDecoration: "none !important",
  },
  linkLabelActive: {
    position: "relative",
    "&:after": {
      backgroundColor: theme.palette.primary.main,
      bottom: 0,
      content: '""',
      left: 0,
      position: "absolute",
      right: 0,
      height: 1,
    },
  },
  links: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  logo: {
    height: 45,
    zIndex: 1,
    [theme.breakpoints.up("sm")]: {
      height: 50,
    },
  },
  root: {
    backgroundColor: "#1A1A1A",
  },
  toolbar: {
    padding: theme.spacing(0, 1),
  },
}));

const links = [
  { label: "Account", href: "/" },
  { label: "Offers", href: "/offers" },
  { label: "Log Out", href: "/account/logout/" },
];

export const Header = ({ path }) => {
  const classes = useStyles();
  const { loading } = useApp();
  const progressProps = loading ? {} : { variant: "determinate", value: 100 };

  const isActive = (href, path) => {
    if (href == "/") return path.indexOf("/offers") != 0;
    if (href == "/offers") return path.indexOf("/offers") == 0;
  };

  return (
    <AppBar className={classes.root}>
      <Container>
        <Toolbar className={classes.toolbar} disableGutters={true}>
          <Link component={RouterLink} to="/">
            <img src={logo} className={classes.logo} alt="logo" />
          </Link>
          <span className={classes.grow} />
          <div className={classes.links}>
            {links.map(({ label, href }, i) => (
              <Link
                key={i}
                className={classes.link}
                component={RouterLink}
                to={href}
              >
                <Button
                  classes={{
                    label: isActive(href, path)
                      ? classes.linkLabelActive
                      : null,
                  }}
                  className={classNames(classes.linkButton, {
                    [classes.linkButtonActive]: isActive(href, path),
                  })}
                >
                  {label}
                </Button>
              </Link>
            ))}
          </div>
        </Toolbar>
      </Container>
      <LinearProgress color="primary" {...progressProps} />
    </AppBar>
  );
};

Header.propTypes = {
  loading: PropTypes.bool,
  path: PropTypes.string,
};

export default Header;
