import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  Link,
  makeStyles,
} from "@material-ui/core";
import { Link as RouterLink } from "@reach/router";

import {
  useApp,
  useApi,
  usePartnerChangeRequests,
  useProfile,
} from "../../common/hooks";
import Title from "../layout/Title";
import Button from "../form/Button";
import ModalConfirm from "../form/ModalConfirm";

const useStyles = makeStyles(() => ({
  partnerImage: {
    width: "100%",
    paddingBottom: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    marginTop: 10,
    border: "1px solid #989898",
    borderRadius: 4,
  },
  title: {
    textTransform: "uppercase",
    color: "#989898",
    fontWeight: 400,
    fontSize: 13,
  },
  description: {
    whiteSpace: "pre-line",
    marginBottom: 20,
    fontSize: 16,
  },
  link: {
    textDecoration: "none !important",
  },
}));

const Home = () => {
  const classes = useStyles();
  const [
    partnerChangeRequests,
    setPartnerChangeRequestsPath,
  ] = usePartnerChangeRequests();
  const [profile] = useProfile();
  const { displaySnackMessage } = useApp();
  const [_, setRequest, submitting, error] = useApi({
    disableAppLoader: true,
    onSuccess: (response) => {
      displaySnackMessage(
        "Account details change request cancelled!",
        "success"
      );
      setPartnerChangeRequestsPath("/partner-change-requests/");
    },
  });

  if (!profile) return null;
  if (partnerChangeRequests === null) return null;

  const partner = { ...profile.partner };

  if (partnerChangeRequests.length) {
    const changeRequest = partnerChangeRequests[0];
    partner.image = changeRequest.image;
    partner.name = changeRequest.name;
    partner.description = changeRequest.description;
    partner.phoneNumber = changeRequest.phoneNumber;
    partner.website = changeRequest.website;
    partner.status = changeRequest.status;
  }

  const onCancel = () => {
    setRequest({
      method: "del",
      path: `/partner-change-requests/${partnerChangeRequests[0].id}/`,
    });
  };

  return (
    <Container maxWidth="lg">
      <Title title="Account Details" />
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} lg={9}>
          <Card style={{ height: "100%" }}>
            <CardContent style={{ height: "100%" }}>
              <Box p={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={8} lg={9}>
                    <Typography display="block" className={classes.title}>
                      Partner Name
                    </Typography>
                    <Typography
                      display="block"
                      gutterBottom
                      variant="h6"
                      className={classes.description}
                    >
                      {partner.name}
                    </Typography>
                    <Typography display="block" className={classes.title}>
                      Description
                    </Typography>
                    <Typography
                      display="block"
                      gutterBottom
                      variant="h6"
                      className={classes.description}
                    >
                      {partner.description}
                    </Typography>
                    <Typography display="block" className={classes.title}>
                      Telephone
                    </Typography>
                    <Typography
                      display="block"
                      gutterBottom
                      variant="h6"
                      className={classes.description}
                    >
                      {partner.phoneNumber || "-"}
                    </Typography>
                    <Typography display="block" className={classes.title}>
                      Website
                    </Typography>
                    <Typography
                      display="block"
                      gutterBottom
                      variant="h6"
                      className={classes.description}
                    >
                      {partner.website || "-"}
                    </Typography>
                    <Typography display="block" className={classes.title}>
                      POS Merchant Code
                    </Typography>
                    <Typography
                      display="block"
                      gutterBottom
                      variant="h6"
                      className={classes.description}
                    >
                      {partner.posMerchantCode}
                    </Typography>
                    <Typography display="block" className={classes.title}>
                      API Key
                    </Typography>
                    <Typography
                      display="block"
                      gutterBottom
                      variant="h6"
                      className={classes.description}
                    >
                      {partner.apiKey}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} lg={3}>
                    <Typography
                      display="block"
                      gutterBottom
                      className={classes.title}
                      align="center"
                    >
                      Partner Logo
                    </Typography>
                    <Box
                      className={classes.partnerImage}
                      style={{ backgroundImage: `url(${partner.image})` }}
                    />
                  </Grid>
                </Grid>
              </Box>
              {partner.status !== "pending" && (
                <Box pt={2} pl={2}>
                  <Link
                    component={RouterLink}
                    to={`/account/details`}
                    className={classes.link}
                  >
                    <Button color="primary" variant="contained">
                      Edit your account details
                    </Button>
                  </Link>
                </Box>
              )}
              {partner.status === "pending" && (
                <Box pt={2} pl={2}>
                  <p>
                    The above details have been submitted for review, they will
                    be live in-app once approved
                  </p>
                  <ModalConfirm
                    body="Your request will be deleted and your account details will not be modified"
                    label="Cancel details change request"
                    title="Cancel account details change request?"
                    onConfirm={onCancel}
                  />
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Card style={{ height: "100%" }}>
            <CardContent style={{ height: "100%" }}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                style={{ height: "100%" }}
              >
                <Box p={2}>
                  <Typography display="block" className={classes.title}>
                    First Name
                  </Typography>
                  <Typography
                    display="block"
                    gutterBottom
                    variant="h6"
                    className={classes.description}
                  >
                    {profile.firstName}
                  </Typography>
                  <Typography display="block" className={classes.title}>
                    Last Name
                  </Typography>
                  <Typography
                    display="block"
                    gutterBottom
                    variant="h6"
                    className={classes.description}
                  >
                    {profile.lastName}
                  </Typography>
                  <Typography display="block" className={classes.title}>
                    Email Address
                  </Typography>
                  <Typography
                    display="block"
                    gutterBottom
                    variant="h6"
                    className={classes.description}
                  >
                    {profile.email}
                  </Typography>
                </Box>
                <Box>
                  <Box pt={2} pl={2}>
                    <Link
                      component={RouterLink}
                      to={`/account/profile`}
                      className={classes.link}
                    >
                      <Button color="primary" variant="contained">
                        Edit your user details
                      </Button>
                    </Link>
                  </Box>
                  <Box pt={2} pl={2}>
                    <Link
                      component={RouterLink}
                      to={`/account/password`}
                      className={classes.link}
                    >
                      <Button color="primary" variant="contained">
                        Change your password
                      </Button>
                    </Link>
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Home;
