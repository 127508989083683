import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Link,
  makeStyles,
} from "@material-ui/core";
import { Link as RouterLink } from "@reach/router";

import ModalConfirm from "../form/ModalConfirm";

const useStyles = makeStyles(() => ({
  offerImage: {
    width: 150,
    height: 150,
    backgroundSize: "cover",
    backgroundPosition: "center",
    marginTop: 10,
    marginBottom: 5,
    border: "1px solid #989898",
    borderRadius: 4,
  },
  description: {
    marginTop: 10,
    whiteSpace: "pre-line",
    fontSize: 14,
  },
  link: {
    textDecoration: "none !important",
  },
  active: {
    display: "inline-block",
    height: 10,
    width: 10,
    borderRadius: "50%",
    background: "#3cff28",
    marginRight: 5,
  },
  inactive: {
    display: "inline-block",
    height: 10,
    width: 10,
    borderRadius: "50%",
    background: "#FF2222",
    marginRight: 5,
  },
  pending: {
    display: "inline-block",
    height: 10,
    width: 10,
    borderRadius: "50%",
    background: "#ff9800",
    marginRight: 5,
  },
  dates: {
    fontWeight: "bold",
    color: "#989898",
    marginTop: 10,
    fontSize: 12,
  },
}));

const BenefitCard = ({ benefit, onCancel }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} md={6}>
      <Link
        component={RouterLink}
        to={`/offers/${benefit.status === "pending" ? "request/" : ""}${
          benefit.id
        }/`}
        className={classes.link}
      >
        <Card style={{ height: "100%" }}>
          <CardContent>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box>
                <Box
                  className={classes.offerImage}
                  style={{ backgroundImage: `url(${benefit.image})` }}
                />
                {benefit.status == "active" && (
                  <Typography display="block" align="center" variant="body2">
                    <div className={classes.active}></div> Active
                  </Typography>
                )}
                {benefit.status == "pending" && (
                  <Typography display="block" align="center" variant="body2">
                    <div className={classes.pending}></div> Pending Review
                  </Typography>
                )}
                {benefit.status == "inactive" && (
                  <Typography display="block" align="center" variant="body2">
                    <div className={classes.inactive}></div> Inactive
                  </Typography>
                )}
              </Box>
              <Box
                className={classes.description}
                flexGrow={1}
                flexShrink={1}
                pl={4}
              >
                <Box>{benefit.title}</Box>
                {benefit.type == "short_term" && (
                  <Box className={classes.dates}>
                    <strong>
                      Valid between:{" "}
                      {moment(benefit.validFrom).format("YYYY-MM-DD")} -{" "}
                      {moment(benefit.validUntil).format("YYYY-MM-DD")}
                    </strong>
                  </Box>
                )}
                <Box className={classes.dates}>
                  <strong>
                    Created at:{" "}
                    {moment(benefit.createdAt).format("YYYY-MM-DD HH:MM")}
                  </strong>
                </Box>
                {benefit.status === "pending" && (
                  <Link
                    component={RouterLink}
                    to={"/offers"}
                    className={classes.link}
                  >
                    <Box pt={2}>
                      <ModalConfirm
                        body="Your submitted offer will be removed from the review queue. You will be able to submit a new offer request upon cancelling a review"
                        label="Cancel offer review"
                        title="Cancel offer review?"
                        onConfirm={() => onCancel(benefit.id)}
                      />
                    </Box>
                  </Link>
                )}
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Link>
    </Grid>
  );
};

export default BenefitCard;
