import API from '@aws-amplify/api'

export default () => {
  let requestId = 0
  const pendingRequests = {}
  const cache = {}

  const isLoading = () => !!Object.keys(pendingRequests).length

  const makeRequest = method => (...args) => {
    const id = requestId++
    pendingRequests[id] = true

    return API[method](...args).finally(() => {
      delete pendingRequests[id]
    })
  }

  return {
    get: makeRequest('get'),
    post: makeRequest('post'),
    patch: makeRequest('patch'),
    put: makeRequest('put'),
    del: makeRequest('del'),
    getCache: key => cache[key],
    setCache: (key, value) => (cache[key] = value),
    isLoading,
  }
}
