import React, { useEffect, useState } from "react";
import moment from "moment";
import { Box, makeStyles } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

import { useApp, useVouchers } from "../../common/hooks";
import config from "../config";

const useStyles = makeStyles(() => ({
  partnerImage: {
    height: 250,
    width: 250,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
}));

const VoucherList = ({ id, status, fields, description }) => {
  const classes = useStyles();
  const { loading } = useApp();
  const { vouchers, count, next, previous, setPath } = useVouchers(id, status);
  const [page, setPage] = useState(0);

  const loadPreviousPage = () => {
    setPage(page - 1);
    setPath(previous.replace(config.api.url, ""));
  };

  const loadNextPage = () => {
    setPage(page + 1);
    setPath(next.replace(config.api.url, ""));
  };

  if (loading) return null;

  return (
    <div>
      {description && <Box pb={2}>{description}</Box>}
      <TableContainer>
        <TablePagination
          component="div"
          count={count}
          rowsPerPage={40}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page",
            onClick: loadPreviousPage,
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
            onClick: loadNextPage,
          }}
          rowsPerPageOptions={[]}
        />
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              {fields.map(({ label }) => (
                <TableCell>{label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {vouchers.map((voucher) => (
              <TableRow key={voucher.id}>
                {fields.map(({ key, type }) => (
                  <TableCell align="left">
                    {type === "date"
                      ? moment(voucher[key]).format("YYYY-MM-DD HH:MM")
                      : voucher[key]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
            {!vouchers.length && (
              <TableRow>
                <TableCell align="center" colspan={fields.length}>
                  No {status} vouchers
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={count}
          rowsPerPage={40}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page",
            onClick: loadPreviousPage,
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
            onClick: loadNextPage,
          }}
          rowsPerPageOptions={[]}
        />
      </TableContainer>
    </div>
  );
};

export default VoucherList;
