import {
  Card,
  CardContent,
  Grid,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Button from "../form/Button";
import Heading from "../typography/Heading";
import PasswordFieldFormik from "./PasswordFieldFormik";
import TextFieldFormik from "./TextFieldFormik";

const SignInSchema = Yup.object().shape({
  username: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().required("Required"),
});

const useStyles = makeStyles((theme) => ({
  link: {
    cursor: "pointer",
  },
  linkArea: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(1.5),
  },
  title: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1),
  },
}));

export const SignInForm = ({
  username = "",
  password = "",
  error,
  onSubmit,
  onForgotPasswordClick,
  onSignUpClick,
}) => {
  const classes = useStyles();
  const [formError, setFormError] = useState();

  const initialValues = { username, password };

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setSubmitting }
  ) => {
    try {
      setFormError();
      await onSubmit(values);
      resetForm(initialValues);
    } catch (err) {
      if (err.form) setFormError(err.form);
      if (err.fields) setErrors(err.fields);
      if (!err.form && !err.fields) {
        setFormError("Error processing");
      }
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    error && setFormError(error.form);
  }, [error]);

  return (
    <Card>
      <CardContent>
        <Heading className={classes.title} variant="h3">
          Sign In
        </Heading>

        {formError && (
          <Typography color="error" gutterBottom>
            {formError}
          </Typography>
        )}
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={SignInSchema}
        >
          {({ handleSubmit, isSubmitting, isValid }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    component={TextFieldFormik}
                    fullWidth={true}
                    label="Email"
                    name="username"
                    type="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={PasswordFieldFormik}
                    fullWidth={true}
                    label="Password"
                    name="password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    className={classes.submit}
                    color="primary"
                    disabled={!isValid || isSubmitting}
                    fullWidth
                    loading={isSubmitting}
                    onClick={handleSubmit}
                    type="submit"
                    variant="contained"
                  >
                    Sign in
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>

        <div className={classes.linkArea}>
          <Link className={classes.link} onClick={onForgotPasswordClick}>
            Forgot password?
          </Link>
        </div>
      </CardContent>
    </Card>
  );
};

export default SignInForm;
