import Amplify from "@aws-amplify/core";
import Auth from "@aws-amplify/auth";
import Analytics from "@aws-amplify/analytics";

export default ({ api, cognito }) => {
  Analytics.disable(); // https://github.com/aws-amplify/amplify-js/issues/3484#issuecomment-505400743

  Amplify.configure({
    Auth: {
      authenticationFlowType: "USER_SRP_AUTH",
      mandatorySignIn: true,
      region: cognito.region,
      userPoolId: cognito.userPoolId,
      userPoolWebClientId: cognito.userPoolWebClientId,
    },
    API: {
      endpoints: [
        {
          name: "default",
          endpoint: api.url,
          custom_header: async () => ({
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          }),
        },
      ],
    },
  });
};
