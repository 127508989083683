import { IconButton, InputAdornment, TextField } from '@material-ui/core'
import {
  VisibilityOutlined as VisibilityIcon,
  VisibilityOffOutlined as VisibilityOffIcon,
} from '@material-ui/icons'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

export const PasswordFieldFormik = ({ field, form, ...props }) => {
  const { touched, errors } = form
  const { name } = field

  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const handleMouseDownPassword = event => event.preventDefault()
  return (
    <TextField
      {...props}
      {...field}
      error={Boolean(touched[name] && errors[name])}
      helperText={touched[name] && errors[name]}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        ),
        ...props,
      }}
      type={showPassword ? 'text' : 'password'}
      variant="outlined"
    />
  )
}

PasswordFieldFormik.propTypes = {
  // `field` and `form` passed in from Formik
  // - https://github.com/jaredpalmer/formik/blob/master/src/Field.tsx
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
}

export default PasswordFieldFormik
