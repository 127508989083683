import { useMemo } from 'react'
import useApi from './useApi'

export default ({ path, parse = o => o, ...args }) => {
  const [response, setRequest, ...rest] = useApi({
    initialRequest: { path },
    ...args,
  })
  const memoizedResponse = useMemo(() => response && parse(response), [
    response,
  ])
  const setPath = path => setRequest(path && { path })

  return [memoizedResponse, setPath, ...rest]
}
