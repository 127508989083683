import React from "react";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  makeStyles,
} from "@material-ui/core";
import { Auth } from "aws-amplify";

import { useApp } from "../../common/hooks";
import ChangePasswordForm from "../form/ChangePasswordForm";
import Title from "../layout/Title";

const useStyles = makeStyles(() => ({
  title: {
    textTransform: "uppercase",
    color: "#989898",
    fontWeight: 400,
    fontSize: 13,
  },
  formContainer: {
    maxWidth: 500,
    margin: "0 auto",
  },
  imageContainer: {
    background: "rgb(255, 255, 255, 0.15)",
  },
}));

const ChangePassword = () => {
  const { displaySnackMessage } = useApp();
  const classes = useStyles();

  const handleSubmit = async ({ oldPassword, newPassword }) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, oldPassword, newPassword);
      displaySnackMessage("Password changed!", "success");
    } catch (error) {
      throw { form: error.message };
    }
  };

  return (
    <Container maxWidth="lg">
      <Title title="Change Password" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card style={{ height: "100%" }}>
            <CardContent style={{ height: "100%" }}>
              <Box p={2} className={classes.formContainer}>
                <ChangePasswordForm onSubmit={handleSubmit} />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ChangePassword;
