import { useState, useEffect, useContext } from "react";
import { ApiContext } from ".";
import { Auth } from "aws-amplify";

import useApp from "../app/useApp";
import { ProfileContext } from "../../modules/profile";

export default ({
  initialRequest,
  disableAppLoader,
  disableAppError,
  disableCache,
  onSuccess, // reprecated
  onError, // reprecated
  onFinish, // reprecated
}) => {
  const api = useContext(ApiContext);
  const app = useApp();
  const { _, setProfile } = useContext(ProfileContext);
  const [request, setRequest] = useState(initialRequest);
  const [response, setResponse] = useState(
    !disableCache && initialRequest && initialRequest.path
      ? api.getCache(initialRequest.path)
      : undefined
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const reset = () => {
    setError();
    setLoading();
    setRequest();
    setResponse();
  };

  const logout = () => {
    setProfile();
    Auth.signOut();
  };

  useEffect(() => {
    if (!request || !request.path) {
      return;
    }

    const { apiName = "default", method = "get", path, init } = request;
    let subscribed = true;

    !disableAppLoader && app.setLoading(true);
    setLoading(true);
    setError();

    api[method.toLowerCase()](apiName, path, init)
      .then((response) => {
        subscribed && setResponse(response);
        !disableCache && api.setCache(path, response);
        onSuccess && onSuccess(response); // reprecated
        request.onSuccess && request.onSuccess(response);
      })
      .catch((error) => {
        /*if (error.response.status === 401) {
          // user is unauthenticated
          logout();
        }*/
        !disableAppError &&
          app.setError({
            message:
              (error.response &&
                error.response.status === 400 &&
                error.response.data &&
                error.response.data.detail) ||
              error.message ||
              error ||
              "Unknown error",
          });
        setError(error);
        onError && onError(error); // reprecated
        request.onError && request.onError(response);
      })
      .finally(() => {
        !disableAppLoader && app.setLoading(api.isLoading());
        setLoading(false);
        onFinish && onFinish(); // reprecated
        request.onFinish && request.onFinish(response);
      });

    return () => {
      subscribed = false;
    };
  }, [request]);

  return [response, setRequest, loading, error, reset];
};
