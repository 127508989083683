import React from "react";
import moment from "moment";
import { Box, Grid, Typography, makeStyles } from "@material-ui/core";

import { useApp } from "../../common/hooks";

const useStyles = makeStyles(() => ({
  offerImage: {
    width: "100%",
    paddingBottom: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    marginTop: 10,
    border: "1px solid #989898",
    borderRadius: 4,
  },
  title: {
    textTransform: "uppercase",
    color: "#989898",
    fontWeight: 400,
    fontSize: 13,
  },
  description: {
    whiteSpace: "pre-line",
    marginBottom: 20,
    fontSize: 16,
  },
}));

const BenefitDetails = ({ benefit }) => {
  const classes = useStyles();
  const { loading } = useApp();

  if (loading) return null;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={8} lg={9}>
        <Typography display="block" className={classes.title}>
          Title
        </Typography>
        <Typography
          display="block"
          gutterBottom
          variant="h6"
          className={classes.description}
        >
          {benefit.title}
        </Typography>
        <Typography display="block" className={classes.title}>
          Offer Type
        </Typography>
        <Typography
          display="block"
          gutterBottom
          variant="h6"
          className={classes.description}
        >
          {benefit.type === "long_term" ? "Long Term" : "Short Term"}
        </Typography>
        <Typography display="block" className={classes.title}>
          Status
        </Typography>
        <Typography
          display="block"
          gutterBottom
          variant="h6"
          className={classes.description}
        >
          {benefit.status}
        </Typography>
        <Typography display="block" className={classes.title}>
          Created At
        </Typography>
        <Typography
          display="block"
          gutterBottom
          variant="h6"
          className={classes.description}
        >
          {moment(benefit.createdAt).format("YYYY-MM-DD HH:MM")}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4} lg={3}>
        <Typography
          display="block"
          gutterBottom
          className={classes.title}
          align="center"
        >
          Offer Image
        </Typography>
        <Box
          className={classes.offerImage}
          style={{ backgroundImage: `url(${benefit.image})` }}
        />
      </Grid>
    </Grid>
  );
};

export default BenefitDetails;
