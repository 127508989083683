import React from "react";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  makeStyles,
} from "@material-ui/core";
import { Auth } from "aws-amplify";

import { parsers } from "../../common/modules/api";
import { useApp, useApi, useProfile } from "../../common/hooks";
import UserDetailsForm from "../form/UserDetailsForm";
import Title from "../layout/Title";

const useStyles = makeStyles(() => ({
  title: {
    textTransform: "uppercase",
    color: "#989898",
    fontWeight: 400,
    fontSize: 13,
  },
  formContainer: {
    maxWidth: 500,
    margin: "0 auto",
  },
  imageContainer: {
    background: "rgb(255, 255, 255, 0.15)",
  },
}));

const MyProfile = () => {
  const [profile, setProfile] = useProfile();
  const [_, setRequest, loading, error] = useApi({
    disableAppLoader: true,
    onSuccess: (response) => {
      setProfile(parsers.parseProfile(response));
      displaySnackMessage("Profile updated!", "success");
    },
  });
  const { displaySnackMessage } = useApp();
  const classes = useStyles();

  const handleSubmit = (data) => {
    const body = {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
    };

    setRequest({
      method: "patch",
      path: "/profile/",
      init: { body },
    });
  };

  if (!profile) return null;

  return (
    <Container maxWidth="lg">
      <Title title="Update User Details" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card style={{ height: "100%" }}>
            <CardContent style={{ height: "100%" }}>
              <Box p={2} className={classes.formContainer}>
                <UserDetailsForm
                  {...profile}
                  error={error && error.message}
                  loading={loading}
                  onSubmit={handleSubmit}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default MyProfile;
