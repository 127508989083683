import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  themeName: "OJ Web",
  palette: {
    background: {
      default: "#1E1E1E",
      paper: "rgba(0,0,0,0.6)",
    },
    primary: {
      contrastText: "#fff",
      grey: "#C5C5C5",
      main: "#DB4133",
    },
    error: {
      main: "#ff1a1a",
    },
    text: {
      primary: "#fff",
      secondary: "#C5C5C5",
    },
    type: "dark",
  },
  typography: {
    fontFamily: '"Rajdhani", sans-serif',
  },
});

theme.overrides = {
  MuiButton: {
    contained: {
      backgroundColor: "rgba(155,155,155,0.32)",
      borderColor: theme.palette.grey[300],
      borderWidth: 2,
      borderStyle: "solid",
      boxShadow: "none",
      color: "#fff",
      "&:hover": {
        boxShadow: "none",
        backgroundColor: theme.palette.grey[300],
        color: "initial",
      },
      "&:focus": {
        backgroundColor: theme.palette.grey[300],
        color: "initial",
      },
    },
    label: {
      fontWeight: 900,
    },
    outlined: {
      borderColor: "#fff",
      borderRadius: 25,
      borderWidth: 3,
      "&$disabled": {
        borderWidth: 3,
      },
    },
    outlinedPrimary: {
      borderColor: theme.palette.primary.main,
      color: "#fff",
      borderWidth: 3,
      "&:hover": {
        borderWidth: 3,
      },
      "&$disabled": {
        borderWidth: 3,
      },
    },
    containedPrimary: {
      backgroundColor: "rgba(219,60,51,0.32)",
      borderColor: theme.palette.primary.main,
      borderWidth: 2,
      borderStyle: "solid",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: "initial",
      },
      "&:focus": {
        backgroundColor: theme.palette.primary.main,
        color: "initial",
      },
    },
    root: {
      fontFamily: '"Rajdhani", sans-serif',
    },
  },
  MuiChip: {
    colorPrimary: {
      color: "initial",
    },
  },
  MuiPickersDay: {
    daySelected: {
      backgroundColor: `${theme.palette.primary.main} !important`,
    },
    current: {
      backgroundColor: `transparent`,
      color: "#fff",
    },
  },
  MuiOutlinedInput: {
    notchedOutline: {
      borderWidth: 2,
    },
  },
  MuiCssBaseline: {
    "@global": {
      body: {
        backgroundSize: "cover",
        backgroundPosition: "50%",
        backgroundAttachment: "fixed",
        backgroundImage: 'url("/oj-l-bg.jpeg")',
      },
    },
  },
};

export default theme;
