import React from "react";
import { Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  header: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    marginBottom: 40,
  },
}));

const Title = ({ title }) => {
  const classes = useStyles();

  return (
    <Box className={classes.header}>
      <h1>{title}</h1>
    </Box>
  );
};

export default Title;
