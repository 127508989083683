import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  Link,
  makeStyles,
} from "@material-ui/core";
import { Link as RouterLink } from "@reach/router";

import Title from "../layout/Title";
import BenefitCard from "../sections/BenefitCard";
import EmptyCard from "../sections/EmptyCard";
import ReplaceCard from "../sections/ReplaceCard";
import {
  useApi,
  useApp,
  useBenefits,
  useBenefitAddRequests,
} from "../../common/hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    "& h2": {
      color: theme.palette.primary.main,
    },
  },
}));

const Benefits = () => {
  const classes = useStyles();
  const { displaySnackMessage, loading } = useApp();
  const [benefits] = useBenefits();
  const [
    benefitAddRequests,
    setBenefitAddRequestsPath,
  ] = useBenefitAddRequests();

  const [_, setRequest, submitting, error] = useApi({
    disableAppLoader: true,
    onSuccess: (response) => {
      displaySnackMessage("Benefit add request cancelled!", "success");
      setBenefitAddRequestsPath("/benefit-add-requests/");
    },
  });

  const onCancel = (id) => {
    setRequest({
      method: "del",
      path: `/benefit-add-requests/${id}/`,
    });
  };

  if (loading || benefits === null) return null;

  const activeLongTermBenefits = benefits.filter(
    ({ type, status }) => type === "long_term" && status === "active"
  );
  const activeShortTermBenefits = benefits.filter(
    ({ type, status }) => type === "short_term" && status === "active"
  );
  const inactiveLongTermBenefits = benefits.filter(
    ({ type, status }) => type === "long_term" && status === "inactive"
  );
  const inactiveShortTermBenefits = benefits.filter(
    ({ type, status }) => type === "short_term" && status === "inactive"
  );

  const longTermAddRequests = benefitAddRequests.filter(
    ({ type, status }) => type === "long_term"
  );
  const shortTermAddRequests = benefitAddRequests.filter(
    ({ type, status }) => type === "short_term"
  );

  return (
    <Container maxWidth="lg" className={classes.root}>
      <Title title="Current Offers" />
      <h2>Long Term</h2>
      <Typography>
        You may have one long term offer active at a time. A request to change
        the long term offer may be submitted every 365 days.
      </Typography>
      <Box pt={4} pb={4}>
        <Grid container spacing={2}>
          {activeLongTermBenefits.map((benefit) => (
            <BenefitCard benefit={benefit} />
          ))}
          {longTermAddRequests.map((benefit) => (
            <BenefitCard benefit={benefit} onCancel={onCancel} />
          ))}
          {!longTermAddRequests.length && (
            <ReplaceCard
              type="long_term"
              isAdd={!activeLongTermBenefits.length}
            />
          )}
        </Grid>
      </Box>
      <h2>Short Term</h2>
      <Typography>
        You may have one short term offer active at a time. If the valid dates
        for an offer overlap with an existing offer, only the offer with the
        most recent start date will be active.
      </Typography>
      <Box pt={4} pb={4}>
        <Grid container spacing={2}>
          {activeShortTermBenefits.map((benefit) => (
            <BenefitCard benefit={benefit} />
          ))}
          {shortTermAddRequests.map((benefit) => (
            <BenefitCard benefit={benefit} onCancel={onCancel} />
          ))}
          {!shortTermAddRequests.length && (
            <ReplaceCard type="short_term" isAdd={true} />
          )}
        </Grid>
      </Box>
      <Title title="Inactive Offers" />
      <h2>Long Term</h2>
      <Box pt={4} pb={4}>
        <Grid container spacing={2}>
          {inactiveLongTermBenefits.map((benefit) => (
            <BenefitCard benefit={benefit} />
          ))}
          {!inactiveLongTermBenefits.length && (
            <EmptyCard text="You have no inactive long term offers" />
          )}
        </Grid>
      </Box>
      <h2>Short Term</h2>
      <Box pt={4} pb={4}>
        <Grid container spacing={2}>
          {inactiveShortTermBenefits.map((benefit) => (
            <BenefitCard benefit={benefit} />
          ))}
          {!inactiveShortTermBenefits.length && (
            <EmptyCard text="You have no inactive short term offers" />
          )}
        </Grid>
      </Box>
    </Container>
  );
};

export default Benefits;
