import { useEffect, useState } from "react";
import { useApiGet, parsers } from "../modules/api";

export default (benefitId, status) => {
	const [count, setCount] = useState(0);
	const [next, setNext] = useState(null);
	const [previous, setPrevious] = useState(null);
	const [vouchers, setVouchers] = useState([]);

	const path = `/vouchers/${benefitId}?status=${status}`;
	const [data, setPath] = useApiGet({
		disableCache: true,
		path,
		parse: parsers.parseVouchers,
	});

	useEffect(() => {
		if (data) {
			setVouchers(data.items);
			setCount(data.count);
			setNext(data.next);
			setPrevious(data.previous);
		}
	}, [data]);

	return { vouchers, count, next, previous, setPath };
};
