import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import MomentUtils from "@date-io/moment";
import moment from "moment";

const useStyles = makeStyles(() => ({
  picker: {
    "& .MuiSvgIcon-root": {
      width: 20,
      height: 20,
    },
  },
}));

export const DateFieldFormik = ({ field, form, fullWidth, label, variant }) => {
  const [selectedDate, handleDateChange] = useState(null);
  const classes = useStyles();

  const { setFieldValue, touched, errors } = form;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        disablePast
        maskChar="-"
        label={label}
        variant={variant}
        fullWidth={fullWidth}
        inputVariant="outlined"
        openTo="date"
        placeholder={moment().format("DD/MM/YYYY")}
        error={Boolean(touched[field.name] && errors[field.name])}
        helperText={touched[field.name] && errors[field.name]}
        format="DD/MM/YYYY"
        value={selectedDate ? moment(selectedDate) : null}
        onChange={(value) => {
          handleDateChange(value);
          setFieldValue(field.name, value);
        }}
        className={classes.picker}
        autoOk={true}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DateFieldFormik;
