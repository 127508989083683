import moment from "moment";
import camelcaseKeys from "camelcase-keys";

const cc = (o) => camelcaseKeys(o, { deep: true });

const formatDate = (date) => moment(date).format("YYYY-MM-DD");

export const parseBenefit = (data, profileData = {}) => ({
  ...cc(data),
  id: +data.id,
  partner: (data.partner || {}).name,
});

export const parseBenefits = ({ results, ...rest }, profileData) => ({
  items: results.map((data) => parseBenefit(data, profileData)),
  ...rest,
});

export const parseAdditionalBenefits = (items) => items.map(parseBenefit);

export const parseProfile = (data) => ({
  ...cc(data),
  name: `${data.first_name} ${data.last_name}`,
  number: data.membership_number,
});

export const parseProfileSubscription = cc;

export const parseFilters = ({ results }) =>
  results.map((data) => parseFilter(data));

const parseFilter = ({ id, name }) => ({ value: id, label: name });

export const parsePartner = (data, profileData = {}) => ({
  ...cc(data),
  id: +data.id,
  benefits: data.benefits.map((benefit) => ({
    ...cc(benefit),
    id: +benefit.id,
  })),
});

export const parsePartners = ({ results, ...rest }, profileData) => ({
  items: results.map((data) => parsePartner(data, profileData)),
  ...rest,
});

export const parseVoucher = (data) => ({
  ...cc(data),
  id: +data.id,
});

export const parseVouchers = ({ results, ...rest }, profileData) => ({
  items: results.map((data) => parseVoucher(data)),
  ...rest,
});
