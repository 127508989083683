import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import ImageUploader from "react-images-upload";
import { navigate } from "@reach/router";
import * as Yup from "yup";

import Title from "../layout/Title";
import {
  useApp,
  useApi,
  useProfile,
  useBenefitAddRequests,
} from "../../common/hooks";
import Button from "../form/Button";
import DateFieldFormik from "../form/DateFieldFormik";
import TextFieldFormik from "../form/TextFieldFormik";

const useStyles = makeStyles(() => ({
  title: {
    textTransform: "uppercase",
    color: "#989898",
    fontWeight: 400,
    fontSize: 13,
  },
  formContainer: {
    maxWidth: 500,
    margin: "0 auto",
  },
  imageContainer: {
    background: "rgb(255, 255, 255, 0.15)",
  },
}));

const BenefitSchema = Yup.object().shape({
  title: Yup.string().required("Required"),
  validFrom: Yup.date().when("type", {
    is: "short_term",
    then: Yup.date("Please enter a valid date").required("Required"),
  }),
  validUntil: Yup.date().when("type", {
    is: "short_term",
    then: Yup.date("Please enter a valid date").required("Required"),
  }),
});

const BenefitSubmit = ({ type }) => {
  const classes = useStyles();
  const [formError, setFormError] = useState();
  const [image, setImage] = useState(null);
  const [imageError, setImageError] = useState();
  const [profile] = useProfile();
  const [benefitAddRequests] = useBenefitAddRequests();
  const { loading } = useApp();
  const { displaySnackMessage } = useApp();
  const [_, setRequest, submitting, error] = useApi({
    disableAppLoader: true,
    onSuccess: (response) => {
      displaySnackMessage("Offer submitted for review!", "success");
      setTimeout(() => {
        navigate(`/offers/`);
      }, 1000);
    },
  });

  useEffect(() => {
    error && setFormError(error.form);
  }, [error]);

  useEffect(() => {
    image && setImageError();
  }, [image]);

  if (!profile) return null;

  let updateAllowed = true;
  if (type === "long_term") {
    if (
      profile.partner.updateAllowedDate &&
      moment(profile.partner.updateAllowedDate) > moment()
    ) {
      updateAllowed = false;
    }
  }

  const currentAddRequests = benefitAddRequests.filter(
    (request) => request.type === type
  );

  if (!updateAllowed || currentAddRequests.length) {
    navigate(`/offers/`);
  }

  const initialValues = {
    title: "",
    validFrom: "",
    validUntil: "",
    type,
  };

  const onSubmit = ({ title, validFrom, validUntil }) => {
    const body = {
      title,
      type,
      valid_from: validFrom || null,
      valid_until: validUntil || null,
      image_raw: image,
    };

    setRequest({
      method: "post",
      path: "/benefit-add-requests/",
      init: { body },
    });
  };

  const onDrop = (pictureFiles, pictureDataURLs) => {
    setImage(pictureDataURLs[0]);
  };

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setSubmitting }
  ) => {
    if (!image) {
      setImageError("Offer image is required");
      return;
    }
    try {
      setFormError();
      setImageError();
      await onSubmit(values);
    } catch (err) {
      if (err.form) setFormError(err.form);
      if (err.fields) setErrors(err.fields);
      if (!err.form && !err.fields) {
        setFormError("Error processing");
      }
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) return null;

  if (type !== "long_term" && type !== "short_term") return null;

  const typeText = type === "long_term" ? "long term" : "short term";

  return (
    <Container maxWidth="lg">
      <Title title={`Submit new ${typeText} offer`} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Box p={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={8} lg={9}>
                    <Box className={classes.formContainer} pt={4}>
                      {formError && (
                        <Typography color="error" gutterBottom>
                          {formError}
                        </Typography>
                      )}
                      <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={BenefitSchema}
                      >
                        {({ handleSubmit, isSubmitting, isValid }) => (
                          <Form>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Field
                                  component={TextFieldFormik}
                                  fullWidth={true}
                                  label="Offer Title / Description"
                                  name="title"
                                  type="text"
                                  multiline
                                  rows={4}
                                />
                              </Grid>
                              {type === "short_term" && (
                                <>
                                  <Grid item xs={12}>
                                    <Field
                                      component={DateFieldFormik}
                                      fullWidth={true}
                                      label="Valid From"
                                      name="validFrom"
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Field
                                      component={DateFieldFormik}
                                      fullWidth={true}
                                      label="Valid Until"
                                      name="validUntil"
                                    />
                                  </Grid>
                                </>
                              )}
                              <Grid item xs={12}>
                                <Box pt={4}>
                                  <Button
                                    className={classes.submit}
                                    color="primary"
                                    disabled={!isValid || isSubmitting}
                                    fullWidth
                                    loading={isSubmitting}
                                    onClick={handleSubmit}
                                    type="submit"
                                    variant="contained"
                                  >
                                    Submit Offer for Review
                                  </Button>
                                </Box>
                              </Grid>
                            </Grid>
                          </Form>
                        )}
                      </Formik>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4} lg={3}>
                    <Typography
                      display="block"
                      gutterBottom
                      className={classes.title}
                      align="center"
                    >
                      Offer Image
                    </Typography>

                    <Box className={image ? null : classes.imageContainer}>
                      <ImageUploader
                        withIcon={false}
                        withPreview={true}
                        label=""
                        buttonText={image ? "" : "Upload Image"}
                        onChange={onDrop}
                        imgExtension={[".jpg", ".bmp", ".png", ".jpeg"]}
                        maxFileSize={10048576}
                        fileSizeError=" file size is too big"
                        singleImage={true}
                      />
                    </Box>
                    {imageError && (
                      <Typography color="error" gutterBottom>
                        {imageError}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default BenefitSubmit;
